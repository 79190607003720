import React from 'react';

const EgodactLogo = props => (
  <span
    {...props}
    dangerouslySetInnerHTML={{
      __html: `
<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 23.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 3543.31 1417.32" style="enable-background:new 0 0 3543.31 1417.32;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#104E8B;}
	.st1{fill:#FFFFFF;}
</style>
<g>
	<rect x="258.79" y="292.79" class="st0" width="1408.73" height="850.73"/>
</g>
<g>
	<path class="st1" d="M423.84,738.43c2.62,70.58,57.29,101.43,120.38,101.43c45.34,0,77.78-7.1,107.57-18.04l14.59,67.79
		c-33.38,14.15-79.65,24.71-136.22,24.71c-126.55,0-200.68-78.12-200.68-197.47c0-107.49,65.26-209.2,190.63-209.2
		c126.7,0,167.95,104.09,167.95,189.42c0,18.31-1.59,32.96-3.48,41.36H423.84z M595.26,669.78c0.43-36.07-15.27-94.91-81.29-94.91
		c-60.66,0-85.9,55.06-90.29,94.91H595.26z"/>
	<path class="st1" d="M1123.77,516.45c-1.89,26.21-3.32,58.54-3.32,111.83v220.95c0,81.67-16.57,139.69-57.66,176.55
		c-40.73,36.13-97.15,47.33-150.84,47.33c-49.53,0-102.22-10.74-135.66-30.84l21.24-75.1c25.14,15.01,66.73,30.07,113.73,30.07
		c64.04,0,112.03-33.83,112.03-117.7v-33.26h-1.6c-22.18,33.99-61.87,57.13-113.55,57.13c-96.6,0-165.42-80.1-165.42-189.99
		c0-128.51,83.2-205.77,176.33-205.77c59.86,0,94.7,28.42,113.39,60.63h1.6l3.57-51.83H1123.77z M1021.94,672.7
		c0-9.47-0.86-18.63-3.1-26.53c-10.46-36.16-38.56-63.33-80.54-63.33c-54.61,0-95.74,47.79-95.74,127.31
		c0,66.14,33.48,119.88,95.31,119.88c37,0,68.44-23.83,79.66-59.68c3.15-10.21,4.41-23.47,4.41-34.57V672.7z"/>
	<path class="st1" d="M1596.71,707.36c0,143.17-100.56,207.36-200.25,207.36c-109.96,0-195.06-75.83-195.06-200.65
		c0-127.76,84.22-206.42,201.69-206.42C1519.09,507.65,1596.71,589.3,1596.71,707.36z M1302.72,711.59
		c0,74.94,37.44,131.58,96.92,131.58c56.52,0,95.76-54.5,95.76-132.87c0-61.21-27.1-131.09-95.13-131.09
		C1331.09,579.2,1302.72,646.66,1302.72,711.59z"/>
	<path d="M2122.69,337.91v458.77c0,39.92,1.43,82.8,3.2,109.23h-87.74l-4.43-61.65h-1.77c-23.26,43.6-70.33,70.45-126.58,70.45
		c-93.08,0-166.79-79.31-166.79-199.04c-0.6-130.5,81-208.03,174.74-208.03c53.38,0,91.8,21.99,109.24,50.75h1.6V337.91H2122.69z
		 M2024.17,678.69c0-7.87-0.52-17.68-2.21-25.67c-8.74-37.94-40.45-69.16-84.74-69.16c-63.54,0-98.7,55.96-98.7,128.15
		c0,71.6,35.49,123.86,97.58,123.86c40.27,0,75.16-27.49,85.08-70.1c2.23-8.78,2.98-18.41,2.98-28.73V678.69z"/>
	<path d="M2448.02,905.92l-6.74-43.39h-2.37c-23.63,30.74-64.41,52.19-114.87,52.19c-79.09,0-122.89-56.45-122.89-115.84
		c0-98.28,87.63-148.03,231.21-147.46v-6.44c0-25.59-10.14-68.16-79.15-67.9c-38.18,0-78.23,12.3-104.8,29.05l-19.53-63.91
		c29.45-17.88,79.92-34.57,141.29-34.57c125.02,0,160.7,78.76,160.7,164.21V812.1c0,35.41,1.77,69.77,6.06,93.82H2448.02z
		 M2434.97,715.17c-69.54-0.92-136.07,13.73-136.07,73.04c0,38.13,25.04,56.1,56.19,56.1c39.33,0,67.68-25.37,77.13-53.9
		c2.12-6.83,2.75-14.58,2.75-21.41V715.17z"/>
	<path d="M2921.48,894.21c-20.4,9.65-61.09,20.11-110.02,20.11c-122.2,0-200.96-78.32-200.96-199.19
		c0-116.62,79.74-207.25,216.85-207.25c36.42,0,72.66,7.94,95.19,17.77l-17.48,74.05c-15.85-7.73-39.47-15.23-74.56-15.23
		c-75.18,0-119.71,54.9-119.36,126.29c0,79.88,52.52,125.68,119.69,125.68c33.76,0,58.15-7.31,77.4-15.37L2921.48,894.21z"/>
	<path d="M3119.76,404.91v111.54h93.77v73.37h-93.77v171.86c0,47.66,12.71,71.89,50.41,71.89c16.86,0,29.5-1.92,38.28-4.29
		l1.98,74.93c-14.8,5.54-40.65,10.11-71.73,10.11c-36.22,0-66.39-12.33-84.71-32.09c-21.17-22.36-30.82-58.21-30.82-109.24V589.82
		h-55.63v-73.37h55.63v-88.21L3119.76,404.91z"/>
</g>
<g>
	<path class="st1" d="M1402.37,953.97c-87.56,0-156.7,43.63-186.42,118.69h105.75c15.63-27.73,40.95-46.88,77.84-46.88
		c35.93,0,60.48,19.47,75.65,46.88h107.19C1554.95,999.88,1489.44,953.97,1402.37,953.97z"/>
</g>
</svg>
`
    }}
  />
);

export default EgodactLogo;
